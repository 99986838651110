import * as m from "mithril";
import {Screen} from "../components/Screen";
import {Section} from "@arco-tech/audit_web_sdk/components/Section";
import {Spinner} from "@arco-tech/audit_web_sdk/components/Spinner";

interface Attrs {}
interface State {}

type Vnode = m.Vnode<Attrs, State>;

export const RedirectingScreen: m.Component<Attrs, State> = {
  view: (vnode: Vnode) => {
    return m(Screen, [
      m(Section, {selector: ".align-center", contentModifiers: ["small"]}, [
        m("h4.weight-regular.margin-bottom-extra-small", "Redirecting..."),
        m(Spinner),
      ]),
    ]);
  },
};
