import * as m from "mithril";
import * as URI from "@arco-tech/audit_web_sdk/URI";
import * as Storage from "@arco-tech/audit_web_sdk/Storage";
import {route} from "../Router";
import {sentence} from "@arco-tech/audit_web_sdk/Grammar";
import {Screen} from "../components/Screen";
import {ErrorMessage} from "@arco-tech/audit_web_sdk/components/ErrorMessage";
import {Section} from "@arco-tech/audit_web_sdk/components/Section";
import {Spinner} from "@arco-tech/audit_web_sdk/components/Spinner";
import {Button} from "@arco-tech/audit_web_sdk/components/Button";
import * as InitiateAPI from "@arco-tech/audit_web_sdk/api/public_forms/InitiateAPI";
import * as PublicFormAPI from "@arco-tech/audit_web_sdk/api/public_forms/PublicFormAPI";
import {stateDataFromSubmissionData} from "@arco-tech/audit_web_sdk/PublicFormSubmission";
import {FormState} from "@arco-tech/audit_web_sdk/FormState";
import {PublishedForm} from "@arco-tech/audit_web_sdk/PublishedForm";
import {PublicForm} from "@arco-tech/audit_web_sdk/PublicForm";
import {decodePayload} from "@arco-tech/audit_web_sdk/JWT"

interface Attrs {}
interface State {
  loadingMessage: string;
  errorMessage?: string;
}

type Vnode = m.Vnode<Attrs, State>;

export const InitiateFormScreen: m.Component<Attrs, State> = {
  oninit: (vnode: Vnode) => {
    vnode.state.loadingMessage = "authenticating";
    const authToken: string | null = URI.param("initiate_auth_token");
    if (authToken) {
      InitiateAPI.initiate(authToken).then((response) => {
        Storage.saveAuthToken(response.token);
        const formState =
          new FormState(
            stateDataFromSubmissionData(
              response.public_form_submission,
            ),
            Storage.saveFormState,
          );
        formState.save();
        vnode.state.loadingMessage = "loading form";
        const publicFormPromise = PublicFormAPI.get(
          decodePayload(authToken).public_form_named_id
        )
          .then((publicForm) => {
            Storage.savePublicForm(new PublicForm(publicForm))
          })
          .catch((error) => {
            console.error(error)
            vnode.state.errorMessage = "failed to load public form"
          })
        const publishedFormPromise = PublicFormAPI.current()
          .then((publishedForm) => {
            Storage.savePublishedForm(new PublishedForm(publishedForm))
          })
          .catch((error) => {
            console.error(error)
            vnode.state.errorMessage = "failed to load form"
          })
        Promise.all([publicFormPromise, publishedFormPromise]).then(() => {
          vnode.state.loadingMessage = "ready"
          route("formDetails")
        })
      }).catch((error) => {
        vnode.state.errorMessage = "authentication failed";
      });
    } else {
      vnode.state.errorMessage = "missing authentication parameter";
    }
  },

  view: (vnode: Vnode) => {
    return m(Screen, [
      m(Section, {contentModifiers: "extra-small"}, [
        m(".align-center", renderContent(vnode)),
      ]),
    ]);
  },
};

function renderContent(vnode: Vnode) {
  if (vnode.state.errorMessage) {
    return [
      m("h1.weight-bold.margin-bottom-none", "Oops!"),
      m("p.margin-top-small.margin-bottom-medium", [
        sentence(vnode.state.errorMessage)
      ]),
      m(".margin-bottom-medium", [
        m("img", {
          src: "/images/icons/oops.svg",
          style: "max-width: 160px",
        }),
      ]),
    ];
  } else {
    return [
      m("h1.weight-bold.margin-bottom-none", "BLP Survey"),
      m("p.margin-top-small.margin-bottom-medium", [
        "Let us know how your business is doing so we can help you better ",
        "in the future"
      ]),
      m("img", {
        src: "/images/icons/feedback.svg",
        style: "max-width: 160px",
      }),
      m(Spinner),
      m("p.margin-top-extra-small", [
        sentence(vnode.state.loadingMessage),
      ]),
    ];
  }
}
