import * as m from "mithril";
import {Screen} from "../components/Screen";
import {Section} from "@arco-tech/audit_web_sdk/components/Section";
import {FormState} from "@arco-tech/audit_web_sdk/FormState";
import {PublicForm} from "@arco-tech/audit_web_sdk/PublicForm";
import {loadFormState, loadPublicForm} from "@arco-tech/audit_web_sdk/Storage";

interface Attrs {}

interface State {
  formState: FormState;
  publicForm: PublicForm;
}

type Vnode = m.Vnode<Attrs>;

export const FormSubmittedScreen: m.Component<Attrs> = {
  oninit: (vnode: Vnode) => {
    vnode.state.formState = loadFormState();
    vnode.state.publicForm = loadPublicForm();
  },

  view: (vnode: Vnode) => {
    const title =
      (vnode.state.publicForm && vnode.state.publicForm.name()) || "BLP Survey"
    return m(Screen, [
      m(Section, {contentModifiers: "extra-small"}, [
        m(".align-center", [
          m("h1.weight-bold.margin-bottom-none", title),
          m("p.margin-top-small", "Your submission has been received"),
          m("img", {
            src: "/images/icons/feedback.svg",
            style: "max-width: 160px",
          }),
        ]),
      ]),
    ]);
  },
};
