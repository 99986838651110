import * as m from "mithril";
import * as PublicFormAPI from "@arco-tech/audit_web_sdk/api/public_forms/PublicFormAPI";
import * as PublicFormSubmissionAPI from
  "@arco-tech/audit_web_sdk/api/public_forms/PublicFormSubmissionAPI";
import {environment} from "../Environment";
import {log} from "@arco-tech/audit_web_sdk/Log";
import {FormState} from "@arco-tech/audit_web_sdk/FormState";
import {PublishedForm} from "@arco-tech/audit_web_sdk/PublishedForm";
import {route} from "../Router";
import {block} from "@arco-tech/audit_web_sdk/BEM";
import {
  loadFormState, 
  loadPublishedForm, 
  loadPreviousValues, 
  savePreviousValues,
  loadPublicForm,
} from "@arco-tech/audit_web_sdk/Storage";
import {RedirectingScreen} from "./RedirectingScreen";
import {PreviousValues} from "@arco-tech/audit_web_sdk/PublicFormSubmission";
import {Button} from "@arco-tech/audit_web_sdk/components/Button";
import {Column} from "@arco-tech/audit_web_sdk/components/Column";
import {ColumnContainer} from "@arco-tech/audit_web_sdk/components/ColumnContainer";
import {FormQuestionBox} from "@arco-tech/audit_web_sdk/components/FormQuestionBox";
import {Screen} from "../components/Screen";
import {Section} from "@arco-tech/audit_web_sdk/components/Section";
import {FormSectionNavigation} from "../components/FormSectionNavigation";
import { PublicForm } from "@arco-tech/audit_web_sdk/PublicForm";

interface Attrs {
  sectionID?: string;
}

interface State {
  formState: FormState;
  publishedForm: PublishedForm;
  previousValues: PreviousValues;
  publicForm: PublicForm;
  isSubmitting: boolean;
}

type Vnode = m.Vnode<Attrs, State>;

export const FormScreen: m.Component<Attrs, State> = {
  oninit: (vnode: Vnode) => {
    vnode.state.publishedForm = loadPublishedForm();
    vnode.state.formState = loadFormState();
    vnode.state.previousValues = null;//loadPreviousValues();
    vnode.state.publicForm = loadPublicForm();
    if (!vnode.state.formState || !vnode.state.publishedForm) {
      vnode.state.redirecting = true;
      route("home");
    } else if (vnode.state.formState.hasSubmitted()) {
      vnode.state.redirecting = true;
      route("formSubmitted");
    }
   /* if (!vnode.state.previousValues) {
      PublicFormAPI.previousSubmissionValues().then( (data)  => {
        if (data) {
          savePreviousValues(data)
          vnode.state.previousValues = data
          m.redraw()
        }
      })
    }*/
  },

  view: (vnode: Vnode) => {
    if (vnode.state.redirecting) {
      return m(RedirectingScreen);
    }
    const {state: {publishedForm, formState}} = vnode;
    const section = formState.findCurrentSection(publishedForm);
    const title = vnode.state.publicForm && vnode.state.publicForm.name()
    return m(Screen, [
      m(Section, {contentModifiers: "large"}, [
        m(".align-center.margin-bottom-large", [
          m("h1.weight-bold.margin-none", title),
          m("p.margin-none", formState.detail("company_name")),
        ]),
        m(ColumnContainer, [
          m(Column, {selector: ".hide-less-than-medium"}, [
            m(FormSectionNavigation, {
              publishedForm,
              formState,
            }),
          ]),
          m(Column, {flex: 1}, [
            m(".box.padding-x-medium.padding-y-small.input-dim", [
              m(ColumnContainer, {
                selector: ".padding-bottom-small",
                modifiers: ["align-end"],
              }, [
                m(Column, {flex: 1}, [
                  m("h3.margin-y-none",
                    formState.hasSubmitted() ? "Sent" :
                      formState.isComplete() ? "Submit" : section.name()),
                ]),
              ]),
              renderContent(vnode),
              m(ColumnContainer, {
                selector: ".margin-top-large.margin-bottom-small",
                modifiers: "justify-space-between",
              }, [
                m(Column, renderSectionPreviousNavigation(vnode)),
                m(Column, renderSectionNextNavigation(vnode)),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]);
  },
};

function renderContent(vnode: Vnode) {
  const {state: {formState, previousValues, publishedForm, validationErrors, }} =
    vnode;
  if (formState.hasSubmitted()) {
    return m(".align-center.margin-y-extra-large", [
      m("img.margin-y-medium", {
        style: "max-width: 150px",
        src: "/images/icons/paper-plane-black.svg",
      }),
      m("p", "Thank you for your input, your feedback is very valuable to us."),
    ]);
  } else if (formState.isComplete()) {
    return m("p.margin-bottom-large", [
      "Please check your answers before you submit.",
    ]);
  } else {
    const section = formState.findCurrentSection(publishedForm);
    return [
      m(".color-grey.fs-italic.ws-pw", section.summary()),
      m(FormQuestionBox, {
        publishedForm,
        formState,
        validationErrors,
        hideIgnored: true,
        previousValues: previousValues 
      })
    ];
  }
}

function renderSectionPreviousNavigation(vnode: Vnode) {
  const {state: {formState, publishedForm}} = vnode;
  if (
    formState.currentSectionIndex(publishedForm) > 0 &&
    formState.hasSubmitted() === false
  ) {
    return m(".flex.flex--align-center.cursor-pointer", {
      onclick: () => {
        PublicFormSubmissionAPI.update(formState);
        if (formState.isComplete()) {
          formState.changeIsComplete(false);
          window.scrollTo(0, 0);
        } else {
          formState.previousSection(publishedForm);
          window.scrollTo(0, 0);
        }
      },
    }, [
      m(block("section-navigation-button", ["left"])),
      m(".margin-left-small", "Previous"),
    ]);
  }
}

function renderSectionNextNavigation(vnode: Vnode) {
  const {state: {formState, publishedForm}} = vnode;
  const totalSections = publishedForm.form().sections().length;
  if (formState.hasSubmitted()) {
    return null;
  } else if (formState.currentSectionIndex(publishedForm) < totalSections - 1) {
    return m(".flex.flex--align-center.cursor-pointer", {
      onclick: () => {
        PublicFormSubmissionAPI.update(formState);
        formState.nextSection(publishedForm);
        window.scrollTo(0, 0);
      },
    }, [
      m(".margin-right-small", "Next"),
      m(block("section-navigation-button", ["right"])),
    ]);
  } else {
    return m(Button, {
      onClick: () => {
        formState.changeIsComplete(true);
        submit(vnode);
        window.scrollTo(0, 0);
      },
    }, "Submit");
  }
}

function submit(vnode: Vnode): void {
  const {state: {formState, publishedForm, isSubmitting}} = vnode;
  if (!isSubmitting) {
    formState.changeHasSubmitted(true);
    PublicFormSubmissionAPI.update(formState)
      .then((submission) => {
        if (publishedForm.formTypeID() !== 9) {
          log("debug",
            "Would send GA conversion, but form type isn't financial survey");
        } else if (environment !== "production") {
          log("debug",
            "Would send GA conversion, but environment isn't production");
        } else {
          (window as any).gtag("event", "conversion", {
            send_to: "AW-688977209/GIYkCI3kutIBELnqw8gC",
          });
        }
      });
  }
}
