import * as m from "mithril";
import * as Router from "./Router";

import {HomeScreen} from "./screens/HomeScreen";
import {CreateFormScreen} from "./screens/CreateFormScreen";
import {InitiateFormScreen} from "./screens/InitiateFormScreen";
import {ReturningFormScreen} from "./screens/ReturningFormScreen";
import {FormDetailsScreen} from "./screens/FormDetailsScreen";
import {FormScreen} from "./screens/FormScreen";
import {FormSubmittedScreen} from "./screens/FormSubmittedScreen";
import {RedirectingScreen} from "./screens/RedirectingScreen";

import {loadFormState} from "@arco-tech/audit_web_sdk/Storage";

Router.init({
  home: HomeScreen,
  createForm: CreateFormScreen,
  initiateForm: InitiateFormScreen,
  returningForm: ReturningFormScreen,
  formDetails: screenRoute(FormDetailsScreen, {requireFormState: true}),
  form: screenRoute(FormScreen, {requireFormState: true}),
  formSubmitted: screenRoute(FormSubmittedScreen, {requireFormState: true}),
});

interface ScreenRouteOptions {
  onRedirect?: () => void;
  requireFormState?: boolean;
}

function screenRoute(
  screen: m.Component<any, any>,
  options: ScreenRouteOptions,
) {
  return {
    onmatch: (args, requestPath, route) => {
      let redirect = false;
      if (options.requireFormState && !loadFormState()) {
        redirect = true;
      }
      if (redirect) {
        if (options.onRedirect) {
          options.onRedirect();
        } else {
          Router.route("home");
        }
        return RedirectingScreen;
      } else {
        return screen;
      }
    },
  };
}
