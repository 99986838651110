import * as m from "mithril"
import * as PublicFormAPI from "@arco-tech/audit_web_sdk/api/public_forms/PublicFormAPI";
import * as PublicFormSubmissionAPI from
  "@arco-tech/audit_web_sdk/api/public_forms/PublicFormSubmissionAPI";
import { Screen } from "../components/Screen";
import { Section } from "@arco-tech/audit_web_sdk/components/Section";
import { ErrorMessage } from "@arco-tech/audit_web_sdk/components/ErrorMessage";
import { FormState } from "@arco-tech/audit_web_sdk/FormState";
import { stateDataFromSubmissionData } from "@arco-tech/audit_web_sdk/PublicFormSubmission";
import { PublishedForm } from "@arco-tech/audit_web_sdk/PublishedForm";
import { route } from "../Router";
import * as Storage from "@arco-tech/audit_web_sdk/Storage";

interface Attrs {
  auth_token: string;
}

interface State {
  error: any;
}

export const ReturningFormScreen: m.Component<Attrs, State> = {
  oninit: ({ attrs: { auth_token: token }, state }) => {
    Storage.saveAuthToken(token);
    Promise.all([
      PublicFormSubmissionAPI.current(),
      PublicFormAPI.current(),
    ])
      .then(([submission, publishedForm]) => {
        Storage.savePublishedForm(new PublishedForm(publishedForm));
        const formState =
          new FormState(
            stateDataFromSubmissionData(submission),
            Storage.saveFormState,
          );
        formState.save();
        route("form");
      })
      .catch((error) => {
        console.error(error)
        state.error = error || "error"
        throw error
      });
  },

  view: ({ state }) => {
    return m(Screen, [
      m(Section, {selector: ".align-center", contentModifiers: ["small"]}, [
        m("h1.margin-y-none", "Please wait a moment"),
        renderMessage(state.error),
      ]),
    ]);
  },
};

function renderMessage(error: any) {
  if (error) {
    console.log(error.message)
    const message =
      error.message == "Unauthorized"
        ? "Sorry your token has expired"
        : "Something went wrong while loading your form"

    return m(ErrorMessage, { error: message });
  } else {
    return m("p", "While we load your business health check");
  }
}
