import * as m from "mithril";
import {Screen} from "../components/Screen";
import {TaskDisplay} from "@arco-tech/audit_web_sdk/components/TaskDisplay";
import {Section} from "@arco-tech/audit_web_sdk/components/Section";
import {CreateForm} from "../components/forms/CreateForm";
import {Changeset} from "@arco-tech/audit_web_sdk/Changeset";
import {FormState} from "@arco-tech/audit_web_sdk/FormState";
import * as FormTypeAPI from "@arco-tech/audit_web_sdk/api/public/FormTypeAPI";
import * as PublicFormAPI from "@arco-tech/audit_web_sdk/api/public_forms/PublicFormAPI";
import * as PublicFormSubmissionAPI from
  "@arco-tech/audit_web_sdk/api/public_forms/PublicFormSubmissionAPI";
import {FormType} from "@arco-tech/audit_web_sdk/FormType";
import {route} from "../Router";
import {Task} from "@arco-tech/audit_web_sdk/Task";
import * as Storage from "@arco-tech/audit_web_sdk/Storage";
import {PublishedForm} from "@arco-tech/audit_web_sdk/PublishedForm";
import {PublicForm} from "@arco-tech/audit_web_sdk/PublicForm";
import {stateDataFromSubmissionData} from "@arco-tech/audit_web_sdk/PublicFormSubmission";

interface Attrs {
  id: string;
}

interface State {
  formTypeTask: Task<FormType>;
}

export const CreateFormScreen: m.Component<Attrs> = {
  oninit: ({attrs: {id}, state}) => {
    state.publishedFormTask = new Task(() => PublicFormAPI.live(id));
    state.formTypeTask = new Task(() => FormTypeAPI.formType(id));
    PublicFormAPI.get(id)
      .then((publicForm) => {
        Storage.savePublicForm(new PublicForm(publicForm))
      })
      .catch((error) => {
        console.error(error)
        state.errorMessage = "failed to load public form"
      })
    state.changeset = new Changeset();
  },

  view: ({attrs: {id}, state}) => {
    return m(Screen, [
      m(TaskDisplay, {
        task: [state.formTypeTask, state.publishedFormTask],
        renderError: () => {
          return m(Section, [
            m(".error.align-center", [
              "This survey has closed or does not exist.",
            ]),
          ]);
        },
        render: ([formType, publishedFormData]) => {
          return m(".align-center", [
            m(Section, {
              contentModifiers: "medium",
              modifiers: "padding-small",
            }, [
              m("h1.weight-bold.margin-bottom-medium", formType.name()),
              m("p.color-grey.margin-none", {
                style: "white-space: pre-wrap;",
              }, formType.description()),
            ]),
            m(Section, {
              contentModifiers: "extra-small",
              modifiers: "padding-small",
            }, [
              m(CreateForm, {
                changeset: state.changeset,
                onSuccess: (changeset: Changeset) => {
                  PublicFormSubmissionAPI.create(
                    id,
                    publishedFormData.id,
                    changeset.getValues() as any,
                  )
                    .then(({token, public_form_submission}) => {
                      const publishedForm =
                        new PublishedForm(publishedFormData);
                      const formState =
                        new FormState(
                          stateDataFromSubmissionData(public_form_submission),
                          Storage.saveFormState,
                        );
                      formState.changeFilteredSectionIDs(
                        publishedForm.form().sections().map((section) => {
                          return section.id();
                        }),
                      );
                      Storage.savePublishedForm(publishedForm);
                      Storage.saveAuthToken(token);
                      Storage.saveFormState(formState);
                      route("form");
                    })
                    .catch((error) => changeset.errorResponse(error));
                  /*
                  state.formState.changeDetails({
                    first_name: changeset.getValue("first_name"),
                    last_name: changeset.getValue("last_name"),
                    accepted_terms_and_conditions:
                      changeset.getValue("accepted_terms_and_conditions"),
                  });
                  PublicFormSubmissionAPI.update(vnode.state.formState);
                  route("form");
                  */
                },
              }),
            ]),
          ]);
        },
      }),
    ]);
  },
}
