import * as m from "mithril";
import {Screen} from "../components/Screen";
import {Section} from "@arco-tech/audit_web_sdk/components/Section";
import {Spinner} from "@arco-tech/audit_web_sdk/components/Spinner";
import {DetailsForm} from "../components/forms/DetailsForm";
import {Changeset} from "@arco-tech/audit_web_sdk/Changeset";
import {FormState} from "@arco-tech/audit_web_sdk/FormState";
import {PublicForm} from "@arco-tech/audit_web_sdk/PublicForm";
import {loadFormState, loadPublicForm} from "@arco-tech/audit_web_sdk/Storage";
import * as PublicFormSubmissionAPI from
  "@arco-tech/audit_web_sdk/api/public_forms/PublicFormSubmissionAPI";
import {route} from "../Router";

interface Attrs {}

interface State {
  formState: FormState;
  publicForm: PublicForm;
  changeset: Changeset;
}

type Vnode = m.Vnode<Attrs>;

export const FormDetailsScreen: m.Component<Attrs> = {
  oninit: (vnode: Vnode) => {
    vnode.state.formState = loadFormState();
    vnode.state.publicForm = loadPublicForm();

    vnode.state.changeset = new Changeset({
      ...vnode.state.formState.details(),
      accepted_terms_and_conditions: false,
    });
    if (vnode.state.formState.hasSubmitted()) {
      route("formSubmitted");
    }
  },

  view: (vnode: Vnode) => {
    const title =
      (vnode.state.publicForm && vnode.state.publicForm.name()) || "BLP Survey"
    
    return m(Screen, [
      m(Section, {contentModifiers: "extra-small"}, [
        m(".align-center", [
          m("h1.weight-bold.margin-bottom-none", title),
          m("p.margin-top-small.margin-bottom-medium.ws-pw", [
            getDescription(vnode.state.publicForm),
          ]),
          m("img.margin-bottom-medium", {
            src: "/images/icons/feedback.svg",
            style: "max-width: 160px",
          }),
          m(DetailsForm, {
            changeset: vnode.state.changeset,
            onSuccess: (changeset: Changeset) => {
              vnode.state.formState.changeDetails({
                first_name: changeset.getValue("first_name"),
                last_name: changeset.getValue("last_name"),
                accepted_terms_and_conditions:
                  changeset.getValue("accepted_terms_and_conditions"),
              });
              PublicFormSubmissionAPI.update(vnode.state.formState);
              route("form");
            },
          }),
        ]),
      ]),
    ]);
  },
};

function getDescription(publicForm: PublicForm | null): string {
  if (publicForm) {
    return publicForm.description()
  } else {
    return "Let us know how your business is doing so we can help you better " +
      "in the future"
  }
}
