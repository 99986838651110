import * as m from "mithril";
import {Changeset} from "@arco-tech/audit_web_sdk/Changeset";
import {Constraints, validate} from "@arco-tech/audit_web_sdk/Validation";
import {ColumnContainer} from "@arco-tech/audit_web_sdk/components/ColumnContainer";
import {Column} from "@arco-tech/audit_web_sdk/components/Column";
import {Form} from "@arco-tech/audit_web_sdk/components/Form";
import {FormField} from "@arco-tech/audit_web_sdk/components/FormField";
import {Input} from "@arco-tech/audit_web_sdk/components/inputs/Input";
import {CountrySelector} from "@arco-tech/audit_web_sdk/components/inputs/CountrySelector";
import {CheckBox} from "@arco-tech/audit_web_sdk/components/inputs/CheckBox";
import {Button} from "@arco-tech/audit_web_sdk/components/Button";

type OnSuccessCallback = (changeset: Changeset) => void;

interface Attrs {
  changeset: Changeset;
  onSuccess: OnSuccessCallback;
}

type Vnode = m.Vnode<Attrs>;

export const CreateForm: m.Component<Attrs> = {
  view: ({attrs: {changeset, onSuccess}}) => {
    return m(Form, {
      changeset: changeset,
      onSubmit: () => submit(changeset, onSuccess),
    }, [
      m(FormField, {
        name: "email",
        changeset: changeset,
        input: Input,
        placeholder: "Email address",
      }),
      m(FormField, {
        name: "location_id",
        changeset: changeset,
        input: CountrySelector,
      }),
      m(ColumnContainer, {modifiers: "small-cut-off"}, [
        m(Column, {selector: ".padding-right-small"}, [
          m(FormField, {
            name: "first_name",
            changeset: changeset,
            input: Input,
            placeholder: "First name",
          }),
        ]),
        m(Column, {selector: ".padding-left-small"}, [
          m(FormField, {
            name: "last_name",
            changeset: changeset,
            input: Input,
            placeholder: "Last name",
          }),
        ]),
      ]),
      m(FormField, {
        name: "company_name",
        changeset: changeset,
        input: Input,
        placeholder: "Company name",
      }),
      m(".align-left", [
        m(".color-grey", [
          "Your information will be protected according to our ",
          m("a.color-grey", {
            href: "https://about.businesslinkpacific.com/privacy/",
            target: "_blank",
          }, "privacy policy."),
          m(FormField, {
            name: "accepted_terms_and_conditions",
            changeset,
            input: CheckBox,
            text: "I accept the privacy policy",
          }),
        ]),
      ]),
      m(Button, {
        selector: ".margin-top-small",
        modifiers: "wide",
        onClick: () => submit(changeset, onSuccess),
      }, "Start"),
    ]);
  },
};

const constraints: Constraints = {
  email: {
    notEmpty: true,
  },
  location_id: {
    notEmpty: true,
  },
  first_name: {
    notEmpty: true,
  },
  last_name: {
    notEmpty: true,
  },
  accepted_terms_and_conditions: {
    true: {message: "Must accept the terms of service and privacy policy"},
  },
};

function submit(changeset: Changeset, onSuccess: OnSuccessCallback): void {
  changeset.clearErrors();
  const {valid, errors} = validate(constraints, changeset.getValues());
  if (valid === false) {
    changeset.validationErrors(errors);
  } else {
    onSuccess(changeset);
  }
}
