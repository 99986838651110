import * as m from "mithril";
import {Screen} from "../components/Screen";
import {Section} from "@arco-tech/audit_web_sdk/components/Section";

interface Attrs {}

type Vnode = m.Vnode<Attrs>;

export const HomeScreen: m.Component<Attrs> = {
  view: (vnode: Vnode) => {
    return m(Screen, {modifiers: "center"}, [
      m(Section, {contentModifiers: "extra-small"}, [
        m(".align-center", [
          m("h1.weight-bold.margin-bottom-none", "BLP Survey"),
          m("p.margin-top-small.margin-bottom-medium", [
            "Please use the link in your email to access this service",
          ]),
          m("img", {
            src: "/images/icons/feedback.svg",
            style: "max-width: 160px",
          }),
        ]),
      ]),
    ]);
  },
};
