import * as m from "mithril";
import {Screen as OriginalScreen} from "@arco-tech/audit_web_sdk/components/Screen";
import {Footer} from "./Footer";

export const Screen: m.Component<any> = {
  view: (vnode) => {
    return m(OriginalScreen, {
      footerComponent: Footer,
      ...vnode.attrs
    }, vnode.children);
  },
}
