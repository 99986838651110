import * as m from "mithril";

interface Attrs {}

type Vnode = m.Vnode<Attrs>;

export const Footer: m.Component<Attrs> = {
  view: (vnode: Vnode) => {
    return m(".footer", [
      m(".footer__content", [
        m(".padding-large"),
      ]),
    ]);
  },
};
